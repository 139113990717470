import React, { useEffect } from 'react';
import { IFileBasicInfo, IFileFullInfo } from '../../../../../interfaces/IFile';
import { getFileById } from '../../../../../services/drive';

import './styles.scss';
import { LoadingAnimation } from '../../../../../components/LoadingAnimation'
import { useHomeContext } from '../../../../../contexts/HomeContext';
import { useHandleApiRequest } from '../../../../../hooks/useHandleApiRequest';


interface IFileListItem {
    file: IFileBasicInfo,
    setFileInView: (fileToView: IFileFullInfo) => void,
}

export function FileListItem(props: IFileListItem) {
    const { isLoading, handleRequest, responseData: fileFullInfo } = useHandleApiRequest<IFileFullInfo>();

    useEffect(() => {
        handleGetFileById();

    }, []);

    async function handleGetFileById() {
        await handleRequest(() => getFileById({ file: props.file }));
    }


    return (
        < li className='folder-file' >
            {
                isLoading ? (
                    <LoadingAnimation />
                ) : (
                    <>
                        {
                            fileFullInfo ? (
                                <>
                                    <div className='d-flex'>
                                        <img src={fileFullInfo.iconUrl} className='me-2' />
                                        <span>{fileFullInfo.name}</span>
                                    </div>

                                    <div className='d-flex'>
                                        <button type="button" className="bg-primary btn btn-primary btn-sm"
                                            onClick={() => { props.setFileInView(fileFullInfo) }}
                                        >View</button>
                                    </div>
                                </>
                            ) : (
                                <div className='d-flex align-items-center'>
                                    <span className="material-symbols-outlined me-2">
                                        lock
                                    </span>
                                    <a className='' href={props.file.linkUrl ? (props.file.linkUrl) : ("#")} target="_blank">
                                        Click here to request access
                                    </a>
                                </div>

                            )
                        }
                    </>
                )
            }
        </li >
    )
}
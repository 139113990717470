import { gcpKeys } from '../config/gcpKeys';
import { ApiException } from '../interfaces/ApiException';
import { IFileBasicInfo, IFileFullInfo } from '../interfaces/IFile';
import { httpStatus } from '../utils/httpStatus';
import { getTokenFromLocalStorage } from '../utils/localStorage';

async function getFileById({ file }: { file: IFileBasicInfo }): Promise<IFileFullInfo | undefined> {
    const fetchResponse = await fetch(`https://www.googleapis.com/drive/v2/files/${file.id}?key=${gcpKeys.apiKey}&supportsAllDrives=true`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().driveAccessToken}`,
            'Content-Type': 'application/json'
        }
    });

    const responseJson = await fetchResponse.json();

    console.log(responseJson);

    const documentWithTreatment = {
        name: responseJson.title,
        embedUrl: responseJson.embedLink,
        iconUrl: responseJson.iconLink,
        id: responseJson.id,
        linkUrl: responseJson.alternateLink
    }


    if ('error' in responseJson && responseJson['error'].code == httpStatus.Unauthorized) {
        const exception: ApiException = {
            lsaMessage: "Your google drive access token has expired. Please sign in again.",
            status: 401,
            hasToSignOut: true
        }
        throw exception;
    }

    if (documentWithTreatment.id === undefined) {
        return undefined;
    }

    return documentWithTreatment;
}


export { getFileById }